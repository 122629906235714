@tailwind base;
@tailwind components;
@tailwind utilities;


.open-sans-normal {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
  }

/* Glass effect for the meet the team image on landing */
#glass {
    /* From https://css.glass */
    background: rgba(0, 0, 0, 0.18);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2.3px);
    -webkit-backdrop-filter: blur(2.3px);
    border: 1px solid rgba(255, 255, 255, 0.6);
}


:root {
    --wpi-red: #ac2b37; /* Official WPI Red Color */
    background-color: rgb(243 244 246);
  }

/* Defaults for links */
a {
    color: #ac2b37;
    text-decoration: none;
}

a:hover {
    color: #6b1c23;
    cursor: pointer;
    display: inline-block; 
}

body {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;   
}

@media (min-width: 768px) {
    body {
        font-size: 18px;
    }
}

.section::before {
    content: '';
    display: block;
    height: 80px;
    margin-top: -80px;
    visibility: hidden;
}
